










































import { User } from "@/store/user";
import { Auth } from "@/store/auth";
import { Core } from "@/store/core";
import _ from 'lodash'
import { Component, Vue, Watch } from "vue-property-decorator";
import MapView from '@/components/core/MapView.vue'
import { Map } from '@/store/map'
import moment from 'moment'
@Component({
    components: {
        MapView
    },
    computed: {}
})

export default class Navbar extends Vue {
    maps: any = null
    response: boolean = false
    test:any = []

    async created() {
        await this.getMap();
        this.response = true;
    }
    locations: any = []

    async getMap() {
        this.maps = await Core.getHttp(`/api/default/map/`)
        let map:any = _.map(this.maps,'location') 
        for (let index = 0; index < this.maps.length; index++) {
            let data =  _.split(map[index], ',' ) 
            if(data.length == 2){
                this.maps[index]['latitude'] = data[0]
                this.maps[index]['longitude'] = data[1]
                this.locations.push({ Latitude: data[0], Longitude: data[1], data: this.maps[index] })
            }
          
        } 
    }

    get dialog() {
        return Map.dialog   
    }
    get detail() {
        return Map.detail
    }
    async closeMap() {
        await Map.setDialog(false)
    }

    async openBrowser(position: any) {
        let me = `${position.coords.latitude},${position.coords.longitude}`
        let to = `${this.detail.latitude},${this.detail.longitude}`
        console.log(me, `https://www.google.com/maps/dir/?api=1&origin=${me}&destination=${to}`);
        window.open(`https://www.google.com/maps/dir/?api=1&origin=${me}&destination=${to}`, '_blank');
    }
    async openMap(location:any) {
        let origin = `19.0199381,99.9218023`
        if (navigator.geolocation) {
           await navigator.geolocation.getCurrentPosition((position:any)=>{
                origin = `${position.coords.latitude},${position.coords.longitude}`
            });
        }  
        window.open(`https://www.google.com/maps/dir/?api=1&origin=${origin}&destination=${location}`);

    }

    dateOut(date:any){
        let convert = moment(date).format('DD/MM/YYYY')
        return convert
    } 

}
